import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import isExternal from 'is-url-external'

const CommonLink = props => {
  const { children, to, className } = props
  if (typeof window !== 'undefined') {
    // Fixes Gatsby build which doesn't allow for window in SSR.
    return isExternal(to) ? (
      <a className={`hover:underline ${className}`} href={to}>
        {children}
      </a>
    ) : (
      <Link className={`hover:underline ${className}`} to={to}>
        {children}
      </Link>
    )
  }
  return null
}

CommonLink.propTypes = {
  children: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
}

export default CommonLink
