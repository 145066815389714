import React from 'react'

const svgStyle = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2',
}

const pathStyle = {
  fill: '#b1bab3',
  fillRule: 'nonzero',
}

const SVGMagnifyingGlass = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 211 214"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={svgStyle}
  >
    <path
      d="M26.469,85.113c0,-32.338 26.306,-58.644 58.644,-58.644c32.331,0 58.637,26.306 58.637,58.644c0,32.337 -26.306,58.637 -58.637,58.637c-32.338,0 -58.644,-26.3 -58.644,-58.637m178.3,94.243l-48.163,-48.162c8.594,-13.294 13.613,-29.106 13.613,-46.081c0,-46.932 -38.175,-85.113 -85.106,-85.113c-46.932,0 -85.113,38.181 -85.113,85.113c0,46.931 38.181,85.112 85.113,85.112c15.587,0 30.206,-4.237 42.787,-11.581l48.794,48.787c3.875,3.875 8.962,5.819 14.037,5.819c5.088,0 10.169,-1.944 14.038,-5.819c7.762,-7.75 7.762,-20.325 0,-28.075"
      style={pathStyle}
    />
  </svg>
)

export default SVGMagnifyingGlass
