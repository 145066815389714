import React, { useState } from 'react'
import CommonLink from '../CommonLink'

const svgContainer = {
  position: 'relative',
  height: 0,
  width: '100%',
  padding: 0,
  paddingBottom: '100%',
}

const svgStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2',
}

const rectStyle = {
  fill: 'white',
}

const rectHoverStyle = {
  fill: '#3C5A99',
}

const SVGFacebookLogo = () => {
  const [hover, setHover] = useState(false)
  return (
    <CommonLink to="https://www.facebook.com/Denver.Regional.Council.of.Governments/">
      <button
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        type="button"
        className="w-10 m-1"
      >
        <div style={svgContainer}>
          <svg
            viewBox="0 0 220 232"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            xmlnsSerif="http://www.serif.com/"
            style={svgStyle}
          >
            <rect
              x="0"
              y="0"
              width="219.481"
              height="231.619"
              style={hover ? rectHoverStyle : rectStyle}
            />
            <path
              d="M98.523,198.529l0,-72.275l-23.05,0l0,-28.162l23.05,0l0,-20.775c0,-24.106 13.95,-37.231 34.325,-37.231c9.763,0 18.15,0.762 20.594,1.112l0,25.188l-14.131,0.006c-11.082,0 -13.225,5.562 -13.225,13.712l0,17.988l26.431,0l-3.444,28.162l-22.987,0l0,72.275l-27.563,0Z"
              fill={hover ? 'white' : '#3D3C3B'}
            />
          </svg>
        </div>
      </button>
    </CommonLink>
  )
}

export default SVGFacebookLogo
