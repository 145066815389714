import React, { useState } from 'react'
import CommonLink from '../CommonLink'

const svgContainer = {
  position: 'relative',
  height: 0,
  width: '100%',
  padding: 0,
  paddingBottom: '100%',
}

const svgStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2',
}

const rectStyle = {
  fill: 'white',
}

const rectHoverStyle = {
  fill: '#0077B5',
}

const SVGLinkedInLogo = () => {
  const [hover, setHover] = useState(false)
  return (
    <CommonLink to="https://www.linkedin.com/company/denver-regional-council-of-governments/">
      <button
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        type="button"
        className="w-10 m-1"
      >
        <div style={svgContainer}>
          <svg
            viewBox="0 0 220 232"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            xmlnsSerif="http://www.serif.com/"
            style={svgStyle}
          >
            <rect
              x="0"
              y="0"
              width="219.481"
              height="231.619"
              style={hover ? rectHoverStyle : rectStyle}
            />
            <rect
              x="32.763"
              y="86.319"
              width="32.406"
              height="109.931"
              fill={hover ? 'white' : '#3D3C3B'}
            />
            <path
              d="M48.971,71.302c-10.4,0 -18.781,-8.875 -18.781,-19.819c0,-10.931 8.381,-19.806 18.781,-19.806c10.357,0 18.769,8.875 18.769,19.806c0,10.944 -8.412,19.819 -18.769,19.819"
              fill={hover ? 'white' : '#3D3C3B'}
            />
            <path
              d="M186.421,196.253l-32.368,0l0,-53.463c0,-12.75 -0.213,-29.15 -16.825,-29.15c-16.844,0 -19.413,13.894 -19.413,28.238l0,54.375l-32.337,0l0,-109.932l31.031,0l0,15.025l0.444,0c4.318,-8.637 14.881,-17.75 30.631,-17.75c32.781,0 38.837,22.757 38.837,52.369l0,60.288Z"
              fill={hover ? 'white' : '#3D3C3B'}
            />
          </svg>
        </div>
      </button>
    </CommonLink>
  )
}

export default SVGLinkedInLogo
