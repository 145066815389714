import React, { useState } from 'react'
import CommonLink from '../CommonLink'

const svgContainer = {
  position: 'relative',
  height: 0,
  width: '100%',
  padding: 0,
  paddingBottom: '100%',
}

const svgStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '2',
}

const rectStyle = {
  fill: 'white',
}

const rectHoverStyle = {
  fill: '#00aced',
}

const SVGTwitterLogo = () => {
  const [hover, setHover] = useState(false)
  return (
    <CommonLink to="https://twitter.com/DRCOGorg">
      <button
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        type="button"
        className="w-10 m-1"
      >
        <div style={svgContainer}>
          <svg
            viewBox="0 0 220 232"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            xmlnsSerif="http://www.serif.com/"
            style={svgStyle}
          >
            <rect
              x="0"
              y="0"
              width="219.481"
              height="231.619"
              style={hover ? rectHoverStyle : rectStyle}
            />
            <path
              d="M167.551,82.493c0.056,1.375 0.088,2.756 0.088,4.15c0,42.393 -30.575,91.281 -86.494,91.281c-17.169,0 -33.15,-5.313 -46.6,-14.413c2.375,0.294 4.794,0.444 7.25,0.444c14.244,0 27.35,-5.125 37.756,-13.731c-13.306,-0.256 -24.531,-9.531 -28.4,-22.281c1.856,0.375 3.763,0.575 5.719,0.575c2.775,0 5.462,-0.388 8.012,-1.119c-13.906,-2.95 -24.387,-15.919 -24.387,-31.463l0,-0.406c4.1,2.406 8.787,3.85 13.775,4.019c-8.163,-5.756 -13.525,-15.575 -13.525,-26.706c0,-5.882 1.494,-11.388 4.112,-16.132c14.994,19.413 37.394,32.182 62.663,33.525c-0.519,-2.35 -0.788,-4.8 -0.788,-7.312c0,-17.719 13.613,-32.081 30.4,-32.081c8.744,0 16.644,3.893 22.188,10.131c6.925,-1.438 13.431,-4.113 19.306,-7.788c-2.269,7.494 -7.087,13.782 -13.369,17.75c6.15,-0.775 12.013,-2.5 17.463,-5.05c-4.075,6.438 -9.231,12.082 -15.169,16.607"
              fill={hover ? 'white' : '#3D3C3B'}
            />
          </svg>
        </div>
      </button>
    </CommonLink>
  )
}

export default SVGTwitterLogo
