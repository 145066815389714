import React from 'react'
import PropTypes from 'prop-types'
import Helmet from './Helmet'
import Footer from './Footer'
import Navbar from './Navbar'
// import useSiteMetadata from './SiteMetadata';
import '../styles/style.css'

const Layout = ({
  ogtitle = 'Metro Vision',
  ogdescription = 'Metro Vision ',
  ogimage = '/img/ogimage.png',
  children,
}) => (
  // const { ogTitle, ogDescription, ogImage } = useSiteMetadata();
  <div className="absolute inset-0">
    <Helmet ogtitle={ogtitle} ogdescription={ogdescription} ogimage={ogimage} />
    <Navbar />
    <div>{children}</div>
    <Footer />
  </div>
)
Layout.propTypes = {
  ogtitle: PropTypes.string,
  ogdescription: PropTypes.string,
  ogimage: PropTypes.object,
  children: PropTypes.object,
}

export default Layout
