import React from 'react'
import PropTypes from 'prop-types'

const Container = ({ id, className, children, outerClassName }) => (
  <section id={id} className={outerClassName}>
    <div className={`container px-6 md:px-12 lg:px-16 2xl:px-40 ${className}`}>
      {children}
    </div>
  </section>
)

Container.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
  outerClassName: PropTypes.string,
}

export default Container
