import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
// import useSiteMetadata from './SiteMetadata';

const Helmet = ({ ogtitle, ogdescription, ogimage }) => (
  <ReactHelmet
     
  >
    <html lang="en" />
    <title>{ogtitle}</title>
    <meta http-equiv="refresh" content="0; url=https://drcog.org/growth-development/metro-vision" />
    <meta name="description" content={ogdescription} />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/img/apple-touch-icon.png"
    />
    <link rel="icon" type="image/png" href="/img/favicon.ico" sizes="64x64" />
    <meta name="theme-color" content="#fff" />
    <meta property="og:title" content={ogtitle} />
    <meta property="og:description" content={ogdescription} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="/" />
    <meta property="og:image" content={ogimage} />
    <meta name="google-site-verification" content="X5cGCO6UfM4OXWU734oFHbe9uYovl6cB__9cFZsluTc" />
    <link
      href="https://fonts.googleapis.com/css?family=Oswald:extra-light,light,regular,medium,semi-bold,bold|Open+Sans:light,regular,semi-bold,bold,extra-bold"
      rel="stylesheet"/>
    <script src="/accessibe.js" type="text/javascript"/>
  </ReactHelmet>
)

export default Helmet
