/**
 * Created by Shawn Matthews @shawnmmatthews on 6/8/17.
 */
import React from 'react'
import SVGDRCOGLogo from './SVG/SVGDRCOGLogo'
import SVGFacebookLogo from './SVG/SVGFacebookLogo'
import SVGTwitterLogo from './SVG/SVGTwitterLogo'
import SVGLinkedInLogo from './SVG/SVGLinkedInLogo'
import CommonLink from './CommonLink'
import Container from './Container'

export default function Footer() {
  return (
    <Container outerClassName="bg-black" className="pt-24 text-center ">
      <div className="flex flex-col md:flex-row md:flex-wrap pb-8">
        <div className="sm:order-1 md:order-1 xl:order-1 flex-grow justify-center w-full md:w-1/2 xl:w-1/4 sm:border-transparent border-white md:border-r xl:border-r  ">
          <CommonLink to="https://drcog.org">
            <SVGDRCOGLogo widthClassName="w-48" />
          </CommonLink>
        </div>
        <div className="font-body sm:order-2 md:order-3 xl:order-2 flex-grow w-full md:w-1/2 xl:w-1/4 text-white sm:border-transparent border-white md:border-r xl:border-r">
          <p>1001 17th Street</p>
          <p>Denver, CO 80202</p>
        </div>
        <div className="font-body sm:order-3 md:order-4 xl:order-3 flex-grow w-full md:w-1/2 xl:w-1/4 text-white sm:border-transparent border-white xl:border-r">
          <p>Phone: 303-455-1000</p>
          <p>Fax: 303-480-6790</p>
        </div>
        <div className="sm:order-4 md:order-2 xl:order-4 flex-grow w-full md:w-1/2 xl:w-1/4">
          <SVGFacebookLogo />
          <SVGTwitterLogo />
          <SVGLinkedInLogo />
        </div>
      </div>
      <div className="font-body w-full bg-black text-white text-center pb-16">
        <CommonLink to="https://drcog.org/photo-attributions">
          Photo Attributions
        </CommonLink>
        <p>©2019 DRCOG</p>
      </div>
    </Container>
  )
}
