import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import useResizeObserver from 'use-resize-observer'
import useHover from './utils/useHover'
import CommonLink from './CommonLink'
import SVGDRCOGLogo from './SVG/SVGDRCOGLogo'
import SVGMagnifyingGlass from './SVG/SVGMagnifyingGlass'
import useOnClickOutside from './utils/useOnClickOutside'

// const navBarLogoDrcog = require('../../../static/img/Navbar/DrcogLogo.png');
// const navBarLogoMV = require('../../../static/img/Navbar/MetrovisionLogo.png');
// const triangle = require('../../../static/img/Navbar/NavTriangle.png');

const ArrowUp = ({ dynamicClassName }) => (
  <div className={`w-0 h-0 ${dynamicClassName}`} />
)

export default function Navbar() {
  const [exploreHoverRef, exploreIsHovered] = useHover()
  const [inPracticeHoverRef, inPracticeIsHovered] = useHover()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [ref, width, height] = useResizeObserver()

  const button = useRef()

  useOnClickOutside(ref, button, () => setMobileMenuOpen(false))

  const handleMenuBtnClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const props = useSpring({
    height: mobileMenuOpen ? height : 0,
    width: mobileMenuOpen ? width : 0, // Needed in order to not have the div show up as a square based on the height.
  })

  return (
    <>
      <div className="bg-black lg:py-2">
        <div className="flex items-center flex-no-shrink text-white container justify-between">
          <div className="flex pl-4">
            <CommonLink to="https://drcog.org/">
              <SVGDRCOGLogo widthClassName="w-24 sm:w-32 md:w-40" />
            </CommonLink>
            <Link to="/" className="text-white hover:text-blue">
              <h1 className="pl-6 text-xl md:text-3xl font-header">
                METRO VISION
              </h1>
            </Link>
          </div>
          <div className="block xl:hidden">
            <button
              ref={button}
              onClick={handleMenuBtnClick}
              type="button"
              className={`hamburger-icon ${mobileMenuOpen ? 'active' : null}`}
            >
              <div />
            </button>
          </div>
          <div className="hidden xl:flex">
            <div className="flex flex-col justify-center pr-4">
              <form
                method="get"
                title="Search Form"
                action="https://cse.google.com/cse/publicurl"
              >
                <div className="border border-black-darkest shadow-inner-dark p-2 flex">
                  <input
                    className="bg-black"
                    type="text"
                    id="q"
                    name="q"
                    title="Search this site"
                    alt="Search Text"
                    maxLength="256"
                  />
                  <input
                    type="hidden"
                    id="cx"
                    name="cx"
                    value="000691971949734672369:idvmdgnadbe"
                  />
                  <button
                    style={{ width: '22px' }}
                    type="submit"
                    id="searchSubmit"
                    title="Submit Search Query"
                  >
                    <SVGMagnifyingGlass />
                  </button>
                </div>
              </form>
            </div>
            <div className="relative group">
              <div className="flex items-center cursor-pointer py-1">
                <button
                  type="button"
                  className="focus:outline-none bg-black p-3 text-black-lighter hover:text-white uppercase"
                >
                  <span>Explore</span>
                </button>
              </div>
              <div className="items-center absolute ml-6 -mt-3 shadow-lg invisible group-hover:visible z-50">
                <ArrowUp
                  dynamicClassName={`ml-4 ${
                    exploreIsHovered ? 'nav-arrow nav-arrow-hover' : 'nav-arrow'
                  }`}
                />
                <ul className="w-56 uppercase bg-black-light">
                  <li>
                    <Link
                      to="/explore/place"
                      className="focus:outline-none px-6 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                      ref={exploreHoverRef}
                    >
                      place
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/explore/mobility"
                      className="focus:outline-none px-6 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                    >
                      mobility
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/explore/environment"
                      className="focus:outline-none px-6 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                    >
                      environment
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/explore/livability"
                      className="focus:outline-none px-6 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                    >
                      livability
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/explore/vitality"
                      className="focus:outline-none px-6 py-3 block text-black-lighter hover:text-white hover:bg-blue"
                    >
                      vitality
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative group">
              <div className="flex items-center cursor-pointer py-1">
                <button
                  type="button"
                  className="focus:outline-none bg-black p-3 text-black-lighter hover:text-white uppercase"
                >
                  <span>In practice</span>
                </button>
              </div>
              <div className="items-center ml-6 -mt-3 absolute shadow-lg invisible group-hover:visible z-50">
                <ArrowUp
                  dynamicClassName={`ml-4 ${
                    inPracticeIsHovered
                      ? 'nav-arrow nav-arrow-hover'
                      : 'nav-arrow'
                  }`}
                />
                <ul className="w-64 uppercase bg-black-light">
                  <li>
                    <Link
                      to="/in_practice/in_practice"
                      className="px-4 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                      ref={inPracticeHoverRef}
                    >
                      In Practice
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/in_practice/performance_measures"
                      className="px-4 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                    >
                      Performance Measures
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/in_practice/urban_centers"
                      className="px-4 py-3 block border-b border-black-lighter text-black-lighter hover:text-white hover:bg-blue"
                    >
                      Urban Centers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/in_practice/idea_exchanges"
                      className="px-4 py-3 block text-black-lighter hover:text-white hover:bg-blue"
                    >
                      Idea Exchanges
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Link
              to="/participate"
              className="flex items-center cursor-pointer py-1"
            >
              <button
                type="button"
                className="focus:outline-none bg-black p-3 text-black-lighter hover:text-white uppercase"
              >
                <span>Participate</span>
              </button>
            </Link>
            <Link
              to="/discover"
              className="flex items-center cursor-pointer py-1"
            >
              <button
                type="button"
                className="focus:outline-none bg-black p-3 text-black-lighter hover:text-white uppercase"
              >
                <span>Discover</span>
              </button>
            </Link>
            <Link to="/about" className="flex items-center cursor-pointer py-1">
              <button
                type="button"
                className="focus:outline-none bg-black p-3 text-black-lighter hover:text-white uppercase"
              >
                <span>About</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <animated.div
        style={{
          ...props,
          width: '100vw',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: '100',
        }}
        className="menu bg-black-light shadow-lg"
      >
        <div className="xl:hidden uppercase text-white flex flex-col" ref={ref}>
          <form
            className="flex justify-center"
            method="get"
            title="Search Form"
            action="https://cse.google.com/cse/publicurl"
          >
            <div className="w-3/5 md:w-3/6 mt-2 border border-black-darker shadow-inner-dark p-2 flex">
              <input
                className="flex-grow bg-black-light"
                type="text"
                id="q"
                name="q"
                title="Search this site"
                alt="Search Text"
                maxLength="256"
              />
              <input
                type="hidden"
                id="cx"
                name="cx"
                value="018015937137608923919:voageylslpi"
              />
              <button
                style={{ width: '22px' }}
                type="submit"
                id="searchSubmit"
                title="Submit Search Query"
              >
                <SVGMagnifyingGlass />
              </button>
            </div>
          </form>
          <p className="pt-6 pb-3 px-4">explore</p>
          <ul className="tree">
            <li>
              <Link className="hover:text-blue" to="/explore/place">
                place
              </Link>
            </li>
            <li>
              <Link className="hover:text-blue" to="/explore/mobility">
                mobility
              </Link>
            </li>
            <li>
              <Link className="hover:text-blue" to="/explore/environment">
                environment
              </Link>
            </li>
            <li>
              <Link className="hover:text-blue" to="/explore/livability">
                livability
              </Link>
            </li>
            <li>
              <Link className="hover:text-blue" to="/explore/vitality">
                vitality
              </Link>
            </li>
          </ul>
          <p className="py-3 px-4">in practice</p>
          <ul className="tree">
            <li>
              <Link className="hover:text-blue" to="/in_practice/in_practice">
                in practice
              </Link>
            </li>
            <li>
              <Link
                className="hover:text-blue"
                to="/in_practice/performance_measures"
              >
                performance measures
              </Link>
            </li>
            <li>
              <Link className="hover:text-blue" to="/in_practice/urban_centers">
                urban centers
              </Link>
            </li>
            <li>
              <Link
                className="hover:text-blue"
                to="/in_practice/idea_exchanges"
              >
                idea exchanges
              </Link>
            </li>
          </ul>
          <Link className="py-3 px-4 hover:text-blue" to="/participate">
            participate
          </Link>
          <Link className="py-3 px-4 hover:text-blue" to="/discover">
            discover
          </Link>
          <Link className="pb-6 pt-3 px-4 hover:text-blue" to="/about">
            about
          </Link>
        </div>
      </animated.div>
    </>
  )
}
